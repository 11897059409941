<template>
  <Modal v-if="showCancelModal">
      <Card class="p-5 flex flex-col max-w-lg">
        <div class>
          <p class="font-bold text-darkPurple mb-5 mt-2 text-xl text-left">
            Cancel Pay Run?
          </p>
          <h4 class="mb-6 text-romanSilver">
            Canceling an approved pay run will clear the schedule and make the
            impacted employees eligible for pay run processing.<br />
            Do you wish to proceed to cancel?
          </h4>
          <div class="flex justify-end">
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn"
              width="96px"
              @click="$emit('cancelModal')"
            >
              Cancel
            </Button>
            <Button class="ml-4 text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="cancelApproval"
            >
              Yes, Cancel Pay Run
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
</template>

<script>
import { mapState } from "vuex";
import Modal from "@/components/Modal";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
    name: 'CancelModal',
    components:{
        Modal,
        Card,
        Button
    },
    props: {
        showCancelModal:{
            type: Boolean,
            default: false
        },
        payRunDataId:{
            type: String,
            default: ''
        }
    },
    computed: {
      ...mapState({
        defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
        appearance: (state) => state.orgAppearance.orgAppearanceData,
      }),
    },
    methods:{
    cancelApproval() {
      this.$emit('loading', true)
      this.$_cancelApproved({ payrunId: this.payRunDataId })
        .then(() => {
          this.$emit('canceled')
          this.$toasted.success(`Cancelled payrun successully.`, {
            duration: 3000
          });
        })
        .catch(err => {
          this.$emit('loading', false)
          const message = err.message
            ? err.message
            : "Canceling payrun failed.";
          this.$toasted.error(message, {
            duration: 6000
          });
        });
    },
    }


}
</script>

<style>

</style>